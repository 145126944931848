module.exports={
    "PLATFORM_ID": "Sport-Nature-Var-Test",
    "API_URL": "https://sportnaturetest.var.fr",
    "BACKOFFICE_URL": "https://snvadmintest.var.fr",
    "ENABLE_HTML_MODE": true,
    "LOGO_FILE": "logo.png",
    "FAVICON": "",
    "SHOW_HOME": true,
    "HOME_TEMPLATE_FILE": {
        "fr": "custom-home.html",
        "en": "custom-home.html",
        "es": "custom-home.html",
        "ca": "custom-home.html",
        "de": "custom-home.html",
        "it": "custom-home.html",
        "nl": "custom-home.html"
    },
    "SHOW_FOOTER": false,
    "FOOTER_TEMPLATE_FILE": "",
    "HEADER_TEMPLATE_FILE": "",
    "ENABLE_DISTRICTS_FILTERING": true,
    "ENABLE_CITIES_FILTERING": true,
    "ENABLE_STRUCTURE_FILTERING": false,
    "GEO_FILTERS_AUTO_CLOSE": false,
    "RULES_FLAT_PAGES_ID": "",
    "FAVORITES_ICON": "heart",
    "SHARE_ICON": "share-alt",
    "PLACEHOLDER_IMAGE": "image_par_defaut",
    "DEFAULT_LANGUAGE": "fr",
    "AVAILABLE_LANGUAGES": {
        "fr": "Français",
	"en": "English"
    },
    "ENABLED_LANGUAGES": ["fr"],
    "ENABLE_TREKS": true,
    "ENABLE_TOURISTIC_CONTENT": true,
    "ENABLE_TOURISTIC_EVENTS": true,
    "ENABLE_DIVES": true,
    "DEFAULT_ACTIVE_CATEGORIES": ["T4"],
    "LIST_EXCLUDE_CATEGORIES": ["C3","C4","C8"],
    "ENABLE_UNIQUE_CAT": false,
    "RANDOMIZE_RESULTS": false,
    "FILTERS_DEFAULT_OPEN": false,
    "DEFAULT_INTEREST": "pois",
    "FACEBOOK_APP_ID": "627260694466507",
    "TWITTER_ID": "Departement_Var",
    "DEFAULT_SHARE_IMG": "",
    "GOOGLE_ANALYTICS_ID": "UA-142856703-1",
    "PERMANENT_TILELAYERS_NAME": "Carte",
    "PERMANENT_TILELAYERS": [
        {
            "LAYER_URL": "https://a.tile.opentopomap.org/{z}/{x}/{y}.png",
            "OPTIONS": {
                "attribution": "Map data: (c) OpenStreetMap contributors, SRTM | Map style: (c) OpenTopoMap (CC-BY-SA)",
                "minZoom": 8,
                "maxZoom": 17
            }
        }
    ],
    "ORTHOPHOTO_TILELAYERS_NAME": "Ortho",
    "ORTHOPHOTO_TILELAYERS": {
        "LAYER_URL": "//wxs.ign.fr/essentiels/geoportail/wmts?LAYER=ORTHOIMAGERY.ORTHOPHOTOS&EXCEPTIONS=image/jpeg&FORMAT=image/jpeg&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&STYLE=normal&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}",
        "OPTIONS": {
            "id": "satellite",
            "attribution": "&copy; IGN - GeoPortail"
        }
    },
    "OPTIONAL_TILELAYERS_NAME": "Optional",
    "OPTIONAL_TILELAYERS": [],

    "SENSITIVE_TILELAYER": false,
    "SENSITIVE_FILE": "sensitiveareas.geojson",
    "SENSITIVE_DEFAULT_ICON": "",
    "SENSITIVE_LAYER_STYLE": {},

    "INFRASTRUCTURES_TILELAYER": false,
    "INFRASTRUCTURES_FILE": "infrastructures.geojson",
    "INFRASTRUCTURES_DEFAULT_ICON": "",
    "INFRASTRUCTURES_LAYER_STYLE": {},

    "SIGNAGES_TILELAYER": false,
    "SIGNAGES_FILE": "signages.geojson",
    "SIGNAGES_DEFAULT_ICON": "",
    "SIGNAGES_LAYER_STYLE": {},

    "LEAFLET_CONF": {
        "CENTER_LATITUDE": 43.40,
        "CENTER_LONGITUDE": 6.30,
        "DEFAULT_ZOOM": 9,
        "DEFAULT_MIN_ZOOM": 8,
        "DEFAULT_MAX_ZOOM": 17
    },
    "MAP_BOUNDS_CONSTRAINTS": null,
    "TREKS_TO_GEOJSON_ZOOM_LEVEL" : 14,
    "UPDATE_MAP_ON_FILTER": false,
    "ACTIVE_MINIMAP": true,
    "MINIMAP_ZOOM": {
        "MINI": 0,
        "MAX": 12
    },
    "MINIMAP_OFFSET": -3,
    "ALWAYS_HIGHLIGHT_TREKS": false,
    "SHOW_FILTERS_ON_MAP": true,
    "FILTER_BY_VIEWPORT_DEFAULT": false,
    "POI_EXPANDED": false,
    "DISPLAY_ASIDES_COUNTERS": false,
    "NEAR_ELEMENTS_CATEGORIES": false,
    "SHOW_ARROWS_ON_ROUTE": false,
    "CENTERON_FITS_BOUNDS": false,
    "MARKER_BASE_ICON": "",
    "POI_BASE_ICON": {
        "iconUrl": null,
        "iconSize": [32, 32]
    },
    "SERVICE_BASE_ICON": "",
    "DEPARTURE_ICON": "",
    "ARRIVAL_ICON": "",
    "DEPARTURE_ARRIVAL_ICON": "",
    "API_DIR": "api",
    "TREKS_DIR": "treks",
    "TREKS_FILE": "treks.geojson",
    "POI_FILE": "pois.geojson",
    "SERVICES_FILE": "services.geojson",
    "FLAT_FILE": "flatpages.geojson",
    "TOURISTIC_EVENTS_DIR": "touristicevents",
    "TOURISTIC_EVENTS_FILE": "touristicevents.geojson",
    "TOURISTIC_CONTENTS_DIR": "touristiccontents",
    "TOURISTIC_CONTENTS_FILE": "touristiccontents.geojson",
    "DEM_FILE": "dem.json",
    "PROFILE_FILE": "profile.json",
    "WARNING_CAT_DIR": "feedback",
    "WARNING_CAT_FILE": "categories.json",
    "WARNING_SUBMIT_URL": "reports/report",
    "STYLES_CONFIG_FILE": "styles.json",
    "APPROVED_BIG": "images/approved-big.png",
    "APPROVED_SMALL": "images/approved-small.png",
    "APPROVED_LABEL": "Approuved",
    "DURATION_FILTER": [
        { "id": 0, "label": "< 1h"},
        { "id": 1, "label": "1h"},
        { "id": 3, "label": "3h"},
        { "id": 5, "label": "5h"},
        { "id": 999, "label": "+ 5h"}
    ],
    "ASCENT_FILTER": [
        { "id": 0, "label": "<100m"},
        { "id": 100, "label": "100"},
        { "id": 300, "label": "300"},
        { "id": 500, "label": "500"},
        { "id": 9999, "label": ">500m"}
    ],
    "LENGTH_FILTER": [
        { "id": 0, "label": "<4km"},
        { "id": 4000, "label": "4km"},
        { "id": 8000, "label": "8km"},
        { "id": 12000, "label": "12km"},
        { "id": 16000, "label": "16km"},
        { "id": 99999, "label": ">16km"}
    ]
}
